import { store } from "@/store/store";
import { MenuItem } from "@/components/Menu/menu-item";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "menu_optionModule",
  store,
  dynamic: true,
})
class menu_optionModule extends VuexModule {
  public menu_options: MenuItem[] = [];
  public menu_options_menu: MenuItem[] = [];
  public menu_option: MenuItem = new MenuItem();

  @Action({ commit: "onGetmenu_options" })
  public async getmenu_options() {
    return await ssmHttpService.get(API.menu_option);
  }
  @Action({ commit: "onGetmenu_options_menu" })
  public async getmenu() {
    return await ssmHttpService.get(API.menu_option + "/menu");
  }

  @Action({ commit: "onGetmenu_option" })
  public async getmenu_option(id: any) {
    return await ssmHttpService.get(API.menu_option + "/" + id);
  }

  @Action
  public async guardarmenu_option(menu_option: MenuItem) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    await ssmHttpService.post(API.menu_option, menu_option.toJson());
    this.getmenu_options();
  }

  @Action
  public async modificarmenu_option(menu_option: MenuItem) {
    await ssmHttpService.put(
      API.menu_option + "/" + menu_option.id,
      menu_option
    );
    this.getmenu_options();
  }

  @Action
  public async eliminarmenu_option(menu_option: MenuItem) {
    await ssmHttpService.delete(
      API.menu_option + "/" + menu_option.id,
      null,
      false
    );
    this.getmenu_options();
  }

  @Mutation
  public onGetmenu_options(res: MenuItem[]) {
    this.menu_options = res;
  }

  @Mutation
  public onGetmenu_options_menu(res: MenuItem[]) {
    this.menu_options_menu = res;
  }

  @Mutation
  public onGetmenu_option(res: MenuItem) {
    this.menu_option = new MenuItem(res);
  }
}
export default getModule(menu_optionModule);
